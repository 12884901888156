<template>
  <div class="CountDown sameStyle" v-html="countTxt"></div>
</template>

<script>
import { formateTimeStamp } from "./tools.js";
export default {
  props: {
    infos: { type: [Array, Object] },
  },
  data() {
    return {
      times: {},
      timeInfos: this.infos,
      countTxt: "",
      IntervalIndex: 0,
      suspend: false,
    };
  },
  destroyed() {
    if (this.IntervalIndex) {
      clearInterval(this.IntervalIndex);
      this.IntervalIndex = 0;
    }
  },
  // watch: {
  //   timeInfos: {
  //     deep: true,
  //     handler(v) {
  //       console.log(v, "监听传进来的时间");
       
  //     },
  //   },
  // },
  created() {
    console.log(this.timeInfos, "传进来的倒计时");
  },
  methods: {
    // 获得距离活动开始还剩余的时间
    mistiming() {
      if (this.IntervalIndex) {
          clearInterval(this.IntervalIndex);
          this.IntervalIndex = 0;
        }
      if (this.timeInfos[0] < 1) {
        this.countTxt = "00:00:00";
        return;
      }
      console.log(this.timeInfos, "离活动开始还剩余的时间");
      var timeStamp = this.timeInfos[0] - this.timeInfos[1];
      this.times = formateTimeStamp(timeStamp);
      console.log(this.times, "处理后的时间格式");
      this.countTxt = `<span>${this.times.hour}</span>:<span>${this.times.min}</span>:<span>${this.times.seconds}</span>`;
      this.IntervalIndex = setInterval(() => {
        if (!this.suspend) {
          if (timeStamp > 0) {
            timeStamp--;
            const newTime = formateTimeStamp(timeStamp);
            this.countTxt = `<span>${newTime.hour}</span>:<span>${newTime.min}</span>:<span>${newTime.seconds}</span>`;
            if (this.timeInfos[0] >= 1800 && timeStamp === 600) {
              this.$emit("overTime", "答题时间还剩10分钟");
            }
          } else {
            this.countTxt = "00:00:00";
            clearInterval(this.IntervalIndex);
            this.$emit("overTime", null);
          }
          this.$emit("timeStamp", timeStamp);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.sameStyle {
  color: #00957e !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 38px !important;
}
</style>