<template>
  <div class="SubmitPop">
    <el-dialog title="" :visible.sync="show" width="460px" :show-close="false"
               :close-on-click-modal="closeOnClickModal"
               :close-on-press-escape="closeOnPressEscape"
               destroy-on-close>
      <div class="header">
        <span>提交</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="body">
        <div class="body-content">
          <slot></slot>
        </div>
        <div class="save-btn">
          <el-button @click="cancel" size="mini">{{ cancelButtonText || '取消' }}</el-button>
          <el-button @click="confirm" type="primary" size="mini" :loading="loading">{{ confirmButtonText || '确定' }}</el-button>
          <!-- <div class="save" @click="confirm">{{ confirmButtonText || '确定' }}</div> -->
          <!-- <div class="cancel" @click="cancel">{{ cancelButtonText || '取消' }}</div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props:{
    confirmButtonText: String,
    cancelButtonText: String,
    closeOnClickModal: {
      type:Boolean,
      default:()=>{
        return false;
      }
    },
    closeOnPressEscape: {
      type:Boolean,
      default:()=>{
        return true;
      }
    },
  },
  components: {

  },
  data () {
    return {
      show: false,
      loading: false,
    }
  },
  methods: {
    confirm () {
      this.loading=true
      this.$emit('confirmPop');
    },
    cancel () {
      this.show = false;
      this.$emit('cancelPop');
    },
    close () {
      this.show = false;
      this.$emit('closePop');
    }
  }
}
</script>

<style lang="less" scoped>
.SubmitPop {
  ::v-deep {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-input__inner {
      border-radius: 40px;
      border: 1px solid #d4d4d4;
      height: 40px;
      line-height: 40px;
    }
    .el-table td,
    .el-table th.is-leaf {
      border-top: 1px solid #ebeef5;
      //  border-bottom: 1px solid #EBEEF5;
    }
  }
  .header {
    height: 35px;
    background: #00957e;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    line-height: 35px;
    align-items: center;
    span {
    }
    .el-icon-circle-close {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .body {
    .body-content {
      text-align: center;
      padding: 2rem 1rem 1rem;
    }
    padding: 0 3rem 2rem 3rem;

    .body-item {
      display: flex;
      align-items: center;
      margin-top: 1.5rem;
      .select {
        display: flex;
        justify-content: space-between;
        > div {
          width: 48%;
        }
      }
    }
    .body-item-textarea {
      margin-top: 0.5rem;
      ::v-deep {
        .el-textarea__inner {
          outline-color: #979797; // 鼠标聚焦边框颜色
        }
        .el-textarea__inner:focus {
          border-color: #979797;
        }
      }
    }
    .save-btn {
      display: flex;
      justify-content: flex-end;
      height: 2rem;
      margin-top: 1rem;
    }
    .save,
    .cancel {
      float: right;
      bottom: 2rem;
      right: 2rem;
      min-width: 40px;
      max-width: 45%;
      cursor: pointer;
      background: #00957e;
      border-radius: 2px;
      color: #fff;
      padding: 6px 20px;
      text-align: center;
    }
    .cancel {
      background: #fff;
      color: rgba(0, 0, 0, 0.65);
      border-radius: 2px;
      border: 1px solid #979797;
      padding: 5px 20px;
      margin-right: 0.5rem;
    }
  }
}
</style>
